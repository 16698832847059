import {Component, OnInit} from '@angular/core';
import { FormGroup, Validators, ReactiveFormsModule, FormsModule, FormBuilder} from '@angular/forms';
import {AuthService} from "../../core/services/auth.service";
import {Router} from "@angular/router";
import {LoaderComponent} from "../../components/loader/loader.component";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, LoaderComponent],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loggedInUser: any = null;
  email: string = '';
  password: string = '';
  name: string = '';
  loading: boolean = false;

  constructor(private authService: AuthService, private router: Router, private fb: FormBuilder) {

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    })
    // this.loginForm = new FormGroup({
    //   email: new FormControl('', Validators.required),
    //   password: new FormControl('', Validators.required),
    // });
  }

  ngOnInit(): void {
    console.log('is logged in', this.authService.isLoggedIn);
    if (this.authService.isLoggedIn) {
      this.router.navigateByUrl('/project/calendar');
      console.log('navigating')
    } else {
      // localStorage.setItem('isLoggedIn', 'true');
      // this.router.navigateByUrl('/project/calendar');
    }
  }

  preLogin() {
    if (this.loginForm.valid) {
      this.loading = true; // Set loading state to true
      this.authService.login(this.loginForm.value.email, this.loginForm.value.password)
        .finally(() => {
          this.loading = false; // Set loading state to false once the request is complete
        });
    }
  }

}
